<template>
  <div class="box" v-if="showDetail">
    <div class="header">
      <div class="header-search">
        <van-nav-bar
          :title="details.name"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
        >
        </van-nav-bar>
      </div>
    </div>
    <div class="content contentbox">
      <BasicTop
        :basicTop="basicTop"
        :numberrows="'2'"
        v-if="basicTop.data.length != 0 && type == 1"
      />
      <GSteps :List="steps" v-if="this.$route.query.appStatus != 2" />
      <!-- 供应商 -->
      <div class="content-show" v-if="type == 1">
        <van-tabs v-model="active" :animated="true">
          <van-tab title="详情页" name="a">
            <van-list :finished="true" finished-text="没有更多了">
              <van-collapse v-model="activeNames">
                <van-collapse-item title="供应商基本信息" name="1" icon="label">
                  <van-cell-group class="group">
                    <van-cell title="供应商名称" :value="details.name" />
                    <van-cell
                      title="供应商类型"
                      :value="details.supplierTypeName"
                    />
                    <van-cell title="专业分类" :value="details.supplierName" />
                    <van-cell
                      title="供应商属性"
                      :value="details.supplierAttributeName"
                    />
                    <van-cell title="申请时间" :value="details.createDate" />
                    <van-cell
                      title="注册资金（万元）"
                      :value="details.originalCurrency"
                    />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item title="变更原因" name="2" icon="label">
                  <div class="texts">{{ details.remark }}</div>
                </van-collapse-item>
                <van-collapse-item
                  title="现场审查"
                  name="3"
                  icon="label"
                  v-if="this.examineFlag == 1"
                >
                  <van-cell-group class="group">
                    <van-cell
                      title="现场审查结果"
                      :value="details.examineResult"
                    />
                    <van-cell
                      title="现场审查结论"
                      :value="details.conclusion"
                    />
                    <van-cell
                      title="现场审查评分"
                      :value="details.examineScore"
                    />
                    <van-cell
                      title="资格审查企业"
                      :value="details.examineScore ? details.nameAbbr : ''"
                    />
                    <van-cell title="现场审查报告"
                      ><div
                        class="preview"
                        v-for="item in details.files"
                        :key="item.fileId"
                      >
                        <a :href="item.href">{{ item.file.title }}</a>
                      </div></van-cell
                    >
                    <van-cell
                      title="现场审查时间"
                      :value="details.examineDate"
                    />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item
                  title="现场审查"
                  name="3"
                  icon="label"
                  v-else
                >
                  <van-cell
                    title="免于现场审查理由"
                    :value="details.notExamineReson"
                  />
                  <van-cell title="免于现场审查相关文件"
                    ><div
                      class="preview"
                      v-for="item in details.examineFiles"
                      :key="item.fileId"
                    >
                      <a :href="item.href">{{ item.file.title }}</a>
                    </div></van-cell
                  >
                </van-collapse-item>
                <van-collapse-item title="相关文件" name="4" icon="label">
                  <van-cell title="" v-if="details.suppfiles.length > 0"
                    ><div
                      class="preview"
                      v-for="item in details.suppfiles"
                      :key="item.fileId"
                    >
                      <a :href="item.href">{{ item.file.title }}</a>
                    </div></van-cell
                  >
                  <van-cell title="" v-else>暂无文件</van-cell>
                </van-collapse-item>
                <van-collapse-item title="申请人信息" name="5" icon="label">
                  <van-cell-group class="group">
                    <van-cell title="姓名" :value="details.createPerson" />
                    <van-cell title="单位" :value="details.nameAbbr" />
                    <van-cell
                      title="联系方式"
                      :value="details.createPersonTel"
                    />
                  </van-cell-group>
                </van-collapse-item>
              </van-collapse>
            </van-list>
          </van-tab>
          <van-tab title="产品目录类型变更" name="b">
            <van-list :finished="true" v-if="details.supplierApplyProducts">
              <van-collapse v-model="activeNames">
                <van-collapse-item title="产品目录" name="6" icon="label">
                  <van-cell-group class="group">
                    <van-cell title="产品目录" style="text-align: center"
                      ><p style="text-align: center">产品目录类型</p></van-cell
                    >
                    <van-cell
                      v-for="item in details.supplierApplyProducts"
                      :key="item.id"
                      :title="item.product.id + ' ' + item.product.name"
                      :value="item.productTypeName"
                    />
                  </van-cell-group>
                </van-collapse-item>
              </van-collapse>
            </van-list>
            <div v-else>暂无数据</div>
          </van-tab>
          <!-- 审核历史 -->
          <van-tab title="审批记录" name="c" v-if="isPass">
            <Task :applyId="stockaffirmId" />
          </van-tab>
        </van-tabs>
      </div>
      <!-- 物流商 -->
      <div class="content-show" v-if="type == 3">
        <van-tabs v-model="active" :animated="true">
          <van-tab title="详情页" name="a">
            <van-list :finished="true" finished-text="没有更多了">
              <van-collapse v-model="activeNames">
                <van-collapse-item title="基本信息" name="1" icon="label">
                  <van-cell-group class="group">
                    <van-cell title="物流商名称" :value="details.name" />
                    <van-cell
                      title="物流商类型"
                      :value="details.supplierTypeName"
                    />
                    <van-cell title="申请时间" :value="details.createDate" />
                    <van-cell title="专业分类" :value="details.supplierName" />
                    <van-cell
                      title="注册资金（万元）"
                      :value="details.originalCurrency"
                    />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item title="产品目录" name="2" icon="label">
                  <van-cell
                    v-for="item in details.supplierApplyProducts"
                    :key="item.id"
                    :title="item.product.id"
                    :value="item.product.name"
                  />
                </van-collapse-item>
                <van-collapse-item title="选用理由" name="3" icon="label">
                  <div class="texts">{{ details.remark }}</div>
                </van-collapse-item>
                <van-collapse-item
                  title="现场审查"
                  name="4"
                  icon="label"
                  v-if="this.examineFlag == 1"
                >
                  <van-cell-group class="group">
                    <van-cell
                      title="现场审查结果"
                      :value="details.examineResult"
                    />
                    <van-cell
                      title="现场审查结论"
                      :value="details.conclusion"
                    />
                    <van-cell
                      title="现场审查评分"
                      :value="details.examineScore"
                    />
                    <van-cell
                      title="资格审查企业"
                      :value="details.examineScore ? details.nameAbbr : ''"
                    />
                    <van-cell title="现场审查报告"
                      ><div
                        class="preview"
                        v-for="item in details.files"
                        :key="item.fileId"
                      >
                        <a :href="item.href">{{ item.file.title }}</a>
                      </div></van-cell
                    >
                    <van-cell
                      title="现场审查时间"
                      :value="details.examineDate"
                    />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item
                  title="现场审查"
                  name="3"
                  icon="label"
                  v-else
                >
                  <van-cell
                    title="免于现场审查理由"
                    :value="details.notExamineReson"
                  />
                  <van-cell title="免于现场审查相关文件"
                    ><div
                      class="preview"
                      v-for="item in details.examineFiles"
                      :key="item.fileId"
                    >
                      <a :href="item.href">{{ item.file.title }}</a>
                    </div></van-cell
                  >
                </van-collapse-item>
                <van-collapse-item title="相关文件" name="5" icon="label">
                  <van-cell title="" v-if="details.suppfiles.length > 0"
                    ><div
                      class="preview"
                      v-for="item in details.suppfiles"
                      :key="item.fileId"
                    >
                      <a :href="item.href">{{ item.file.title }}</a>
                    </div></van-cell
                  >
                  <van-cell title="" v-else>暂无文件</van-cell>
                </van-collapse-item>
                <van-collapse-item title="申请人信息" name="6" icon="label">
                  <van-cell-group class="group">
                    <van-cell title="姓名" :value="details.createPerson" />
                    <van-cell title="单位" :value="details.nameAbbr" />
                    <van-cell
                      title="联系方式"
                      :value="details.createPersonTel"
                    />
                  </van-cell-group>
                </van-collapse-item>
              </van-collapse>
            </van-list>
          </van-tab>
          <!-- 审核历史 -->
          <van-tab title="审批记录" name="b" v-if="isPass">
            <Task :applyId="stockaffirmId" />
          </van-tab>
        </van-tabs>
      </div>
      <!-- 服务商 -->
      <div class="content-show" v-if="type == 4">
        <van-tabs v-model="active" :animated="true">
          <van-tab title="详情页" name="a">
            <van-list :finished="true" finished-text="没有更多了">
              <van-collapse v-model="activeNames">
                <van-collapse-item title="基本信息" name="1" icon="label">
                  <van-cell-group class="group">
                    <van-cell title="服务商名称" :value="details.name" />
                    <van-cell
                      title="服务商类型"
                      :value="details.supplierTypeName"
                    />
                    <van-cell title="申请时间" :value="details.createDate" />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item title="产品目录" name="2" icon="label">
                  <van-cell
                    v-for="item in details.supplierApplyProducts"
                    :key="item.id"
                    :title="item.product.id"
                    :value="item.product.name"
                  />
                </van-collapse-item>
                <van-collapse-item title="选用理由" name="3" icon="label">
                  <div class="texts">{{ details.remark }}</div>
                </van-collapse-item>
                <van-collapse-item
                  title="现场审查"
                  name="4"
                  icon="label"
                  v-if="this.examineFlag == 1"
                >
                  <van-cell-group class="group">
                    <van-cell
                      title="现场审查结果"
                      :value="details.examineResult"
                    />
                    <van-cell
                      title="现场审查结论"
                      :value="details.conclusion"
                    />
                    <van-cell
                      title="现场审查评分"
                      :value="details.examineScore"
                    />
                    <van-cell
                      title="资格审查企业"
                      :value="details.examineScore ? details.nameAbbr : ''"
                    />
                    <van-cell title="现场审查报告"
                      ><div
                        class="preview"
                        v-for="item in details.files"
                        :key="item.fileId"
                      >
                        <a :href="item.href">{{ item.file.title }}</a>
                      </div></van-cell
                    >
                    <van-cell
                      title="现场审查时间"
                      :value="details.examineDate"
                    />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item
                  title="现场审查"
                  name="3"
                  icon="label"
                  v-else
                >
                  <van-cell
                    title="免于现场审查理由"
                    :value="details.notExamineReson"
                  />
                  <van-cell title="免于现场审查相关文件"
                    ><div
                      class="preview"
                      v-for="item in details.examineFiles"
                      :key="item.fileId"
                    >
                      <a :href="item.href">{{ item.file.title }}</a>
                    </div></van-cell
                  >
                </van-collapse-item>
                <van-collapse-item title="相关文件" name="5" icon="label">
                  <van-cell title="" v-if="details.suppfiles.length > 0"
                    ><div
                      class="preview"
                      v-for="item in details.suppfiles"
                      :key="item.fileId"
                    >
                      <a :href="item.href">{{ item.file.title }}</a>
                    </div></van-cell
                  >
                  <van-cell title="" v-else>暂无文件</van-cell>
                </van-collapse-item>
                <van-collapse-item title="申请人信息" name="6" icon="label">
                  <van-cell-group class="group">
                    <van-cell title="姓名" :value="details.createPerson" />
                    <van-cell title="单位" :value="details.nameAbbr" />
                    <van-cell
                      title="联系方式"
                      :value="details.createPersonTel"
                    />
                  </van-cell-group>
                </van-collapse-item>
              </van-collapse>
            </van-list>
          </van-tab>
          <!-- 审核历史 -->
          <van-tab title="审批记录" name="b" v-if="isPass">
            <Task :applyId="stockaffirmId" />
          </van-tab>
        </van-tabs>
      </div>
      <!-- 监造商 -->
      <div class="content-show" v-if="type == 5">
        <van-tabs v-model="active" :animated="true">
          <van-tab title="详情页" name="a">
            <van-list :finished="true" finished-text="没有更多了">
              <van-collapse v-model="activeNames">
                <van-collapse-item title="基本信息" name="1" icon="label">
                  <van-cell-group class="group">
                    <van-cell title="监造商名称" :value="details.name" />
                    <van-cell title="申请时间" :value="details.createDate" />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item title="产品目录" name="2" icon="label">
                  <van-cell
                    v-for="item in details.supplierApplyProducts"
                    :key="item.id"
                    :title="item.product.id"
                    :value="item.product.name"
                  />
                </van-collapse-item>
                <van-collapse-item title="选用理由" name="3" icon="label">
                  <div class="texts">{{ details.remark }}</div>
                </van-collapse-item>
                <van-collapse-item
                  title="现场审查"
                  name="4"
                  icon="label"
                  v-if="this.examineFlag == 1"
                >
                  <van-cell-group class="group">
                    <van-cell
                      title="现场审查结果"
                      :value="details.examineResult"
                    />
                    <van-cell
                      title="现场审查结论"
                      :value="details.conclusion"
                    />
                    <van-cell
                      title="现场审查评分"
                      :value="details.examineScore"
                    />
                    <van-cell
                      title="资格审查企业"
                      :value="details.examineScore ? details.nameAbbr : ''"
                    />
                    <van-cell title="现场审查报告"
                      ><div
                        class="preview"
                        v-for="item in details.files"
                        :key="item.fileId"
                      >
                        <a :href="item.href">{{ item.file.title }}</a>
                      </div></van-cell
                    >
                    <van-cell
                      title="现场审查时间"
                      :value="details.examineDate"
                    />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item
                  title="现场审查"
                  name="3"
                  icon="label"
                  v-else
                >
                  <van-cell
                    title="免于现场审查理由"
                    :value="details.notExamineReson"
                  />
                  <van-cell title="免于现场审查相关文件"
                    ><div
                      class="preview"
                      v-for="item in details.examineFiles"
                      :key="item.fileId"
                    >
                      <a :href="item.href">{{ item.file.title }}</a>
                    </div></van-cell
                  >
                </van-collapse-item>
                <van-collapse-item title="相关文件" name="5" icon="label">
                  <van-cell title="" v-if="details.suppfiles.length > 0"
                    ><div
                      class="preview"
                      v-for="item in details.suppfiles"
                      :key="item.fileId"
                    >
                      <a :href="item.href">{{ item.file.title }}</a>
                    </div></van-cell
                  >
                  <van-cell title="" v-else>暂无文件</van-cell>
                </van-collapse-item>
                <van-collapse-item title="申请人信息" name="6" icon="label">
                  <van-cell-group class="group">
                    <van-cell title="姓名" :value="details.createPerson" />
                    <van-cell title="单位" :value="details.nameAbbr" />
                    <van-cell
                      title="联系方式"
                      :value="details.createPersonTel"
                    />
                  </van-cell-group>
                </van-collapse-item>
              </van-collapse>
            </van-list>
          </van-tab>
          <!-- 审核历史 -->
          <van-tab title="审批记录" name="b" v-if="isPass">
            <Task :applyId="stockaffirmId" />
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <!-- <div class="footer" v-if="!isPass">
      <div class="footer-left" @click="agree">
        <span><van-icon name="success" size="20" /></span>
        <span>同意</span>
      </div>
      <div class="footer-right" @click="noagree">
        <span><van-icon name="cross" size="20" /></span>
        <span>不同意</span>
      </div>
    </div> -->
    <div class="footer" v-if="!isPass">
      <div class="tips">请在电脑端操作</div>
    </div>
  </div>
  <div class="box" v-else>
    <div class="header">
      <div class="header-search">
        <van-nav-bar
          :title="details.name"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
        >
        </van-nav-bar>
      </div>
    </div>
    <Noagree />
  </div>
</template>
<script>
import { Dialog } from "vant";
import { Toast } from "vant";
import noagree from "../noagree.vue";
export default {
  components: { noagree },
  name: "gsnProductsChangeConfirmDetails",
  data() {
    return {
      examineFlag: 1,
      showDetail: true,
      loading: false,
      finished: true,
      isLoading: false,
      active: "a",
      activeNames: ["1", "2", "3", "4", "5", "6"],
      details: [],
      steps: [],
      workflowId: "",
      basicTop: {
        data: [],
        columns: [
          {
            prop: "name",
            label: "供应商名称",
          },
          {
            prop: "supplierTypeName",
            label: "供应商类型",
          },
          {
            prop: "supplierName",
            label: "专业分类",
          },
          {
            prop: "supplierAttributeName",
            label: "供应商属性",
          },
        ],
      },
    };
  },
  async created() {
    await this.getDetail();
    if (this.$route.query.appStatus != 2) {
      await this.getTask();
    }
  },
  computed: {
    stockaffirmId() {
      return this.$route.query.id;
    },
    isPass() {
      return this.$route.query.appStatus != 1;
    },
    type() {
      return this.$route.query.classificationType;
    },
  },
  methods: {
    // onPreview(item) {
    //   let query = {
    //     id: item.fileId,
    //     type: item.file.fileType
    //   };
    //   this.$router.push({ name: "previewGsn", query })
    // },
    formatDate(date) {
      let d = new Date(date);
      const month =
        d.getMonth() + 1 >= 10 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1);
      const day = d.getDate() >= 10 ? d.getDate() : "0" + d.getDate();
      return `${d.getFullYear()}-${month}-${day}`;
    },
    async getDetail() {
      const params = {
        applyId: this.stockaffirmId,
      };
      let data = await this.$api.gsn.getSupplierApplyInfo(params);
      this.workflowId = data.supplierApplyInfo.workflowid;
      this.examineFlag = data.supplierApplyInfo.examineFlag;
      let supplierAttributeName = "企业自采";
      if (
        data.supplierApplyInfo &&
        data.supplierApplyInfo.supplierApplyProducts &&
        data.supplierApplyInfo.supplierApplyProducts.length > 0
      ) {
        const supplierApplyProducts =
          data.supplierApplyInfo.supplierApplyProducts;
        const len = supplierApplyProducts.length;
        for (let i = 0; i < len; i++) {
          if (supplierApplyProducts[i].product.classficationSign === 1) {
            supplierAttributeName = "集团化采购";
            break;
          }
        }
        data.supplierApplyInfo.supplierApplyProducts.forEach((item) => {
          item.productTypeName =
            item.productTypeId == 1
              ? "生产"
              : item.productTypeId == 2
              ? "代理"
              : "流通";
        });
      }
      // 详情头部
      const supplierInfo = {
        ...data.supplierInfo,
        supplierName: data.professional ? data.professional.name : "暂无数据",
        supplierTypeName: data.supplierTypeName,
        supplierAttributeName: supplierAttributeName,
      };
      this.basicTop.data = supplierInfo;
      // TAB页面数据
      let details = {
        // 供应商名称
        name: data.supplierInfo.name,
        // 供应商类型
        supplierName: data.professional ? data.professional.name : "暂无数据",
        // 专业分类
        supplierTypeName: data.supplierTypeName || "暂无数据",
        // 供应商属性
        supplierAttributeName: supplierAttributeName,
        // 申请时间
        createDate: this.formatDate(data.supplierApplyInfo.createDate),
        // 注册资金
        originalCurrency: data.supplierInfo
          ? data.supplierInfo.originalCurrency
          : "暂无数据",
        // 选用理由
        remark: data.supplierApplyInfo.remark,
        // 申请人姓名
        createPerson: data.supplierApplyInfo.createPerson,
        // 申请人单位
        nameAbbr: data.enterprise.nameAbbr,
        // 申请人联系方式
        createPersonTel: data.supplierApplyInfo.createPersonTel,
        // 产品目录
        supplierApplyProducts: data.supplierApplyInfo.supplierApplyProducts,
        // 免于现场的理由
        notExamineReson: data.supplierApplyInfo.notExamineReson,
        // 免于现场的文件
        examineFiles: data.supplierApplyInfo.examineFiles,
        // 相关文件
        suppfiles: data.supplierApplyInfo.files,
      };
      if (this.examineFlag == 1) {
        details = {
          ...details,
          // 现场审查结果
          examineResult: data.examine
            ? data.examine.examineScore
              ? data.examine.examineResult == 1
                ? "合格"
                : "不合格"
              : "未做现场审查"
            : "未做现场审查",
          // 现场审核结论
          conclusion: data.examine
            ? data.examine.examineScore
              ? data.examine.conclusion
              : ""
            : "",
          // 现场审查评分
          examineScore: data.examine ? data.examine.examineScore : "",
          // 资格审查企业
          // nameAbbr: data.enterprise.nameAbbr,
          // 现场审查报告
          files: data.examine
            ? data.examine.examineScore
              ? data.examine.files
              : []
            : [],
          // 现场审查时间
          examineDate: data.examine
            ? data.examine.examineScore
              ? this.formatDate(data.examine.examineDate)
              : ""
            : "",
        };
      }
      if (this.type == 3 || this.type == 4) {
        details.supplierTypeName = "暂无数据";
        let typeName = [];
        if (data.supplierTypeData.length > 0) {
          data.supplierTypeData.forEach((item) => {
            typeName.push(item.name);
          });
          details.supplierTypeName = typeName.join(",");
        }
      }
      if (details.files && details.files.length > 0) {
        details.files.forEach((item) => {
          let api = "/api-gsn/FileAction!downloadByApp/";
          // let title = item.file.title.substring(
          //   item.file.title.lastIndexOf(".") + 1
          // );
          // if (item.file.fileType == 1) {
          //   api = "/api-gsn/FileAction!showImageByApp/";
          // }
          item.href = `${api}${item.fileId}-${item.file.title}`;
        });
      }
      if (details.examineFiles && details.examineFiles.length > 0) {
        details.examineFiles.forEach((item) => {
          let api = "/api-gsn/FileAction!downloadByApp/";
          // let title = item.file.title.substring(
          //   item.file.title.lastIndexOf('.') + 1
          // )
          // if (item.file.fileType == 1) {
          //   api = '/api-gsn/FileAction!showImageByApp/'
          // }
          item.href = `${api}${item.fileId}-${item.file.title}`;
        });
      }
      if (details.suppfiles && details.suppfiles.length > 0) {
        details.suppfiles.forEach((item) => {
          let api = "/api-gsn/FileAction!downloadByApp/";
          // let title = item.file.title.substring(
          //   item.file.title.lastIndexOf('.') + 1
          // )
          // if (item.file.fileType == 1) {
          //   api = '/api-gsn/FileAction!showImageByApp/'
          // }
          item.href = `${api}${item.fileId}-${item.file.title}`;
        });
      }
      this.details = details;
    },
    async getTask() {
      const params = {
        workflowId: this.workflowId,
      };
      let data = await this.$api.gsn.getTaskList(params);
      this.steps = data;
    },
    onClickLeft() {
      if (this.showDetail) {
        this.$router.go(-1);
      } else {
        this.showDetail = true;
      }
    },
    onRefresh() {
      this.loading = true;
    },
    onLoad() {},
    listDetails() {},
    agree() {
      Dialog.confirm({
        title: "审批",
        message: "是否同意",
      })
        .then(() => {
          this.auditApply(1);
        })
        .catch(() => {
          // on cancel
        });
    },
    noagree() {
      // this.$router.push({ name: "noagree" });
      this.showDetail = false;
    },
    async auditApply(flag, auditOpinion = "审核同意") {
      const params = {
        applyId: this.stockaffirmId,
        applyType: 0,
        auditFlag: flag,
        auditOpinion: auditOpinion,
        workflowId: this.workflowId,
      };
      let data = await this.$api.gsn.auditApply(params);
      console.log(data);
      Toast.success("提交成功");
      let query = {
        audit: 1,
      };
      this.$router.push({ name: "gsnProductsChangeConfirm", query });
    },
  },
};
</script>
<style lang="less" scoped>
.footer-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #70e6ea;
}
.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f77269;
}
/deep/.group {
  .van-cell {
    color: #8f8f8f;
  }
}
/deep/.van-cell {
  background-color: transparent;
}
/deep/.van-cell {
  font-size: 12px;
}
/deep/.van-collapse-item__content {
  font-size: 12px;
  padding: 0;
}
/deep/.van-cell__value {
  color: #333;
}
.supplies {
  margin-top: 10px;
  min-height: 160px;
  background-color: #fff;
  padding: 0.3rem;
  border-radius: 9px;
  margin: 0.3rem;
  .supplies-title {
    font-weight: 400;
    line-height: 25px;
    font-size: 13px;
  }
  .supplies-text {
    color: #8f8f8f;
    line-height: 25px;
  }
}
.record {
  margin-top: 10px;
}
.texts {
  padding: 10px 20px;
  text-indent: 2em;
  line-height: 24px;
}
.preview {
  color: blue;
}
</style>
